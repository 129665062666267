import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  updateUser,
  updateBusiness,
  getOtp,
  deleteAccount,
  getTeamById,
  socialLogin,
  socialAuthRegister,
  verifyOtp,
  loginUrl,
  RegisterUrl,
  resendOtp,
  imageUrl,
  verifyResetPassword,
  forgotPassword,
  resetPassword,
  changePassword,
  CheckConnectAccountStatusUrl
} from "./endpoint";
import { baseUrl } from "../../utilis/baseUrl";
import { toast } from "react-toastify";
import { register, login, update, logout } from "./slices";
import { store } from "../store";
import { useNavigate } from "react-router-dom";

export const AuthApi = createApi({
  reducerPath: "AuthApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Post", "Get"],
  endpoints: (builder) => ({
    getTeam: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getTeamById}/${params}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // dispatch(threads(data?.data?.threads));
          // toast.success("Team fetched successfully");
          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
          // `onError` side-effect

          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["Get"],
    }),
    createUser: builder.mutation({
      query: (response) => {
        response.fcmToken = localStorage.getItem("fcmToken");
        return {
          url: RegisterUrl,
          method: "POST",
          body: { ...response, phone: `+${response.phone}` },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        localStorage.setItem("spinningState", false);
        try {
          const { data } = await queryFulfilled;

          dispatch(register(data?.data));
          toast.success("user created successfully", {
            autoClose: 5000,
          });

          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))

          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      invalidatesTags: ["Post"],
    }),
    login: builder.mutation({
      query: (newPost) => {
        newPost.fcmToken = localStorage.getItem("fcmToken");
        return {
          url: loginUrl,
          method: "POST",
          body: newPost,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data?.data));
          toast.success("user logged in successfully", {
            autoClose: 5000,
          });
          localStorage.setItem("login", false);
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          if (
            err?.error?.data?.data?.message ===
            "Please wait for your request to be approved."
          ) {
            localStorage.setItem("errorMessage", true);
          } else {
            toast.error(err?.error?.data?.data?.message, {
              autoClose: 5000,
            });
          }
          localStorage.setItem("login", false);
        }
      },
      invalidatesTags: ["Post"],
    }),
    getOtp: builder.mutation({
      query: (response) => {
        return {
          url: getOtp,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        localStorage.setItem("spinningState", false);
        try {
          const { data } = await queryFulfilled;
          toast.success("OTP sent successfully", {
            autoClose: 5000,
          });
          dispatch(login());
          // localStorage.setItem("signup", true);
          localStorage.setItem("singup", false);
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
          localStorage.setItem("signup", false);
        }
      },
      providesTags: ["Post"],
    }),
    verifyOtp: builder.mutation({
      query: (response) => {
        return {
          url: verifyOtp,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        localStorage.setItem("spinningState", false);
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;
          if (data?.success) {
            toast.success("OTP is verified successfully", {
              autoClose: 5000,
            });
          }
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
            // setTimeout(() => { window.location.reload(); }, 2000)
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
          // setTimeout(() => { window.location.reload(); }, 2000)
        }
      },
      providesTags: ["Post"],
    }),
    resendOtp: builder.mutation({
      query: (response) => {
        return {
          url: resendOtp,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        localStorage.setItem("spinningState", false);
        const { data } = await queryFulfilled;

        // dispatch(messageCreated('Fetching posts...'))

        try {
          toast.success("OTP resend successfully", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))

          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    sendImage: builder.mutation({
      query: (formData) => {
        return {
          url: imageUrl,
          method: "POST",
          body: formData,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        // `onStart` side-effect
        localStorage.setItem("spinningState", false);
        // dispatch(messageCreated('Fetching posts...'))

        try {
          toast.success("file uploaded successfully", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          console.log("error===>", err);
          if (err?.error?.error) {
            toast?.error("Network error");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      },
      providesTags: ["Post"],
    }),
    forgotPassword: builder.mutation({
      query: (response) => {
        return {
          url: forgotPassword,
          method: "POST",
          body: response,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        localStorage.setItem("spinningState", false);
        try {
          const { data } = await queryFulfilled;

          toast.success("OTP send successfully", {
            autoClose: 5000,
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    verifyResetPassword: builder.mutation({
      query: (response) => {
        return {
          url: verifyResetPassword,
          method: "POST",
          body: response,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        const { data } = await queryFulfilled;
        localStorage.setItem("spinningState", false);

        // dispatch(messageCreated('Fetching posts...'))

        try {
          if (data?.success) {
            toast.success("OTP verified successfully", {
              autoClose: 5000,
            });
          }
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    resetUserPassword: builder.mutation({
      query: (response) => {
        return {
          url: resetPassword,
          method: "POST",
          body: response,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        localStorage.setItem("spinningState", false);
        try {
          const { data } = await queryFulfilled;

          toast.success("Password reset successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    changeUserPassword: builder.mutation({
      query: (response) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: changePassword,
          method: "PUT",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log("response,", data?.data);
          // dispatch(update(data?.data?.data));
          toast.success("Password updated successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    socialRegister: builder.mutation({
      query: (response) => {
        response.fcmToken = localStorage.getItem("fcmToken");
        return {
          url: socialAuthRegister,
          method: "POST",
          body: response,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data?.data));
          toast.success("User loggedIn successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
          window.location.reload();
        }
      },
      providesTags: ["Post"],
    }),
    updateUser: builder.mutation({
      query: (response) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${updateUser}/${response?.id}`,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
        const user = store.getState().Auth.user?.user;

          const { data } = await queryFulfilled;
          if (user?.userType === "payee" && user?.accountType !== "teamMember") {
            let updatedObject = {
              ...user,
              username: data?.data?.data?.username,
              profileImg: data?.data?.data?.profileImg,
            }
            dispatch(update(updatedObject));
          } else {
            let updatedObject = {
              ...user,
              username: data?.data?.data?.username,
              profileImg: data?.data?.data?.profileImg,
              placeId: data?.data?.data?.placeId,
              long: data?.data?.data?.long,
              lang: data?.data?.data?.lang,
              address: data?.data?.data?.address,
            }
            dispatch(update(updatedObject));
          }
          // dispatch(update(data?.data?.data));
          toast.success("User updated successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    updateBusiness: builder.mutation({
      query: (response) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${updateBusiness}/${response?.id}`,
          method: "PUT",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(update(data?.data?.data));
          toast.success("Business updated successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    deleteUser: builder.mutation({
      query: (response) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: `${deleteAccount}/${response}`,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          method: "DELETE",
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(logout());
          toast.success("User deleted successfully", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
    checkConnectAccountStatus: builder.mutation({
      query: (response) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: `${CheckConnectAccountStatusUrl}`,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          toast.success("Connect Account Successfully linked", {
            autoClose: 5000,
          });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }

          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000,
          });
        }
      },
      providesTags: ["Post"],
    }),
  }),
});

export const {
  useGetTeamQuery,
  useDeleteUserMutation,
  useCheckConnectAccountStatusMutation,
  useSocialRegisterMutation,
  useUpdateUserMutation,
  useUpdateBusinessMutation,
  useResetUserPasswordMutation,
  useForgotPasswordMutation,
  useVerifyResetPasswordMutation,
  useChangeUserPasswordMutation,
  useSendImageMutation,
  useResendOtpMutation,
  useVerifyOtpMutation,
  useGetOtpMutation,
  useCreateUserMutation,
  useLoginMutation,
} = AuthApi;
