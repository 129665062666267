import React from "react";
import "./sideBarUserInfo.scss";
import { useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import RatingStars from "../../RatingStars";
import NoRating from "../../../Assets/Images/iCons/norating.svg";
// const userImg = require("../../../Assets/Images/userImag.png");

const SideBarUserInfo = ({ className }) => {
  const user = useSelector((state) => state?.Auth?.user.user);
  const popoverTop = (
    <Popover id="popover-positioned-left" title="Popover left">
      <Popover.Body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>You need to receive 5 reviews to see your rating.</p>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className={className}>
        <h1>EasyTipping</h1>
        <div
          className="m-2"
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "100%",
            border: "#499662 solid 1px",
            backgroundImage: `url(${user?.profileImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no repeat",
          }}
        />
        <h5>{`${user?.username}`}</h5>
        {!user?.isTeamOwner &&
          (user?.reviewsCount >= 5 ? (
            <RatingStars
              value={user.rating}
              text={user.rating}
              color={`#FFB33E`}
            />
          ) : (
            <>
              <OverlayTrigger
                trigger={["hover"]}
                placement="top"
                overlay={popoverTop}
              >
                <img className="no-rating" src={NoRating} alt="no-rating" />
              </OverlayTrigger>
            </>
          ))}

        {/* <p>{user?.email}</p> */}
      </div>
      <div
        className={`d-flex align-items-center justify-content-center my-4 ${
          className === "d-none" ? "d-block" : "d-none"
        }`}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "100%",
            border: "#499662 solid 1px",
            backgroundImage: `url(${user?.profileImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no repeat",
          }}
        />
      </div>
    </>
  );
};

export default SideBarUserInfo;
