// ProtectedRoute.js

import { Navigate } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Store/Auth/slices";
const ProtectedRoutes = ({ Component, requiredRoles }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.Auth?.user?.user);
  const isTeamOwner = user?.isTeamOwner;
  const isTeamAdmin = user?.userTeam?.isteamAdmin ?? false;

  console.log("HERE", isTeamAdmin, user);

  let tipReciever = user?.userType;
  console.log("user--->", user);
  let userRole = "admin";
  if (user?.userType === "payer") {
    console.log("isTeamAdmin==>", user?.userType);
    userRole = "tipGiver";
  } else if (user?.isTeamAdmin) {
    userRole = "teamAdmin";
  } else if (user?.accountType === "business") {
    userRole = "businessUser";
  } else if (user?.accountType === "individual") {
    userRole = "individualRole";
  } else if (user?.accountType === "teamMember") {
    userRole = "teamMember";
  } else {
    if (isTeamOwner) {
      userRole = "teamOwner";
    } else if (tipReciever === "payee") {
      userRole = "tipReciever";
    }
  }

  if (isTeamAdmin) {
    console.log("HERE12", isTeamAdmin);
    userRole = "teamAdmin";
  }
  const isTipReceiverDetailsURL = window?.location?.pathname?.startsWith(
    "/tip-receiver-details/"
  ); // Tip Receiver Details URL
  if (isTipReceiverDetailsURL) {
    dispatch(logout());
  }
  const isAllowed = requiredRoles.includes(userRole);
  return <>{isAllowed ? <Component /> : <Navigate to="/Home" />}</>;
};

export default ProtectedRoutes;
