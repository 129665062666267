import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./SelectCountry.scss"; // Your custom SCSS styles if needed
import { Row, Col } from "react-bootstrap";
import usaFlag from "./Flags/us.svg";
import franceFlag from "./Flags/fr.svg";
import germanyFlag from "./Flags/de.svg";
import italyFlag from "./Flags/it.svg";
import spainFlag from "./Flags/es.svg";
import arabicFlag from "./Flags/sa.svg";
import i18next from "../../../../Localization";
import { useTranslation } from "react-i18next";
function ChangeLanguageModal({ icon, listName, className, children, state }) {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let { t } = useTranslation();
  const [select, setSelect] = useState(localStorage.getItem("defaultlanguage"));
  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem("activeIndex")
  );

  useEffect(() => {
    localStorage.setItem("defaultlanguage", select);
    i18next.changeLanguage(select);
  }, [select]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const languages = [
    { code: "en", name: "English", flag: usaFlag },
    { code: "fr", name: "French", flag: franceFlag },
    { code: "de", name: "German", flag: germanyFlag },
    { code: "it", name: "Italian", flag: italyFlag },
    { code: "es", name: "Spanish", flag: spainFlag },
    { code: "ar", name: "Arabic", flag: arabicFlag }
  ];

  return (
    <>
      {state === "mainScreen" ? (
        <button
          type="button"
          onClick={handleShow}
          className=""
          style={{
            padding: "0 0 0 0",
            background: "transparent",
            cursor: "pointer",
            color: "#ffa26b",
            border: "none",
            "&:hover": {
              // Fixed the hover syntax
              background: "transparent",
            },
          }}
        >
          <h4 className="mb-0 mt-2">{t("Select Language")}</h4>
        </button>
      ) : (
        <p variant="primary" onClick={handleShow}>
          <li className="d-flex">
            {icon}
            <p className={className}>
              <p>{listName}</p>
            </p>
          </li>
        </p>
      )}

      <Modal className="modal-dialog-centered " show={show} onHide={handleClose}>
        <Modal.Header className="border-0 ">
          <Modal.Title className="text-center w-100 text-white mt-3">
            Select language 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          {children}
          <div className="text-center">
            <ul className="language-list">
              {languages.map((language, i) => (
                <li
                  key={language.code}
                  onClick={(e) => {
                    setActiveIndex((state) => i);
                    localStorage.setItem("activeIndex", i);
                    window.location.reload();

                    // handleClose();
                    setSelect((state) => language?.code);
                    // setRefresh(!refresh)
                  }}
                  className={`${
                    i == activeIndex ? "selected-list" : "inactive"
                  }`}
                >
                  <Row className="w-100 justify-content-center">
                    <Col className="col-3">
                      <img
                        src={language.flag}
                        alt={language.name}
                        className="flag-image"
                      />
                    </Col>
                    <Col className="col-3">
                      <span>{language.name}</span>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button
            className="btn m-0 w-100"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeLanguageModal;
