import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./Store/store";
import "bootstrap/dist/css/bootstrap.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode>  */}
    <GoogleOAuthProvider
      clientId={"132235317391-pk2inci3apgbi157aiuj1mq5pk3431n5.apps.googleusercontent.com"}
    >
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
    <ToastContainer autoClose={2000} />
    {/* </React.StrictMode> */}
  </Provider>
);
