import React from "react";
import Sidebar from "../../Shared/Nabar/SideBar/SideBar";

const MainHeader = () => {
  return (
    <>
      <span className="d-md-block d-none  ">
        <Sidebar />
      </span>
    
    </>
  );
};

export default MainHeader;
