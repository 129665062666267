import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import {
  allRoles,
  teamOwnerRole,
  userRole,
  tipRecieverRole,
  businessUserRole,
  individualRole,
  teamMemberRole ,teamMember, teamAdminRole
} from "./Role";
import { store } from "../Store/store";


const routes = [
  {
    path: "/login",
    component: lazy(() => import("../Pages/AuthPages/Login")),
    ispublic: true,
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/teamRoles",
    component: lazy(() => import("../Pages/AuthPages/TeamRoles")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/welcome",
    component: lazy(() => import("../Pages/AuthPages/HomePage")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/",
    component: lazy(() => import("../Pages/AuthPages/LandingPage")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/resetPassword",
    component: lazy(() => import("../Pages/AuthPages/ResetPassword")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/forgotpassword",
    component: lazy(() => import("../Pages/AuthPages/ForgotPassword")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/roles",
    component: lazy(() => import("../Pages/AuthPages/SelectRole")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/accountTypes",
    component: lazy(() => import("../Pages/AuthPages/AccountTypes")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/ImportantInformation",
    component: lazy(() =>
      import("../Pages/IndividualAccount/ImportantInformation/index")
    ),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/verification",
    component: lazy(() => import("../Pages/AuthPages/Verification")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/individualRegister",
    component: lazy(() => import("../Pages/AuthPages/SignUp")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/createTeamAccount",
    component: lazy(() => import("../Pages/AuthPages/SignUp")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/signUp",
    component: lazy(() => import("../Pages/AuthPages/SignUp")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/Subscription",
    component: lazy(() => import("../Shared/Subscription/index")),
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/joinTeam",
    component: lazy(() => import("../Pages/AuthPages/SignUp")),
    ispublic: true,
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/businessAccount",
    component: lazy(() => import("../Pages/AuthPages/SignUp")),
    ispublic: true,
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/QRScan",
    component: lazy(() => import("../Pages/HomePages/QRScan/QRScan")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/notifications",
    component: lazy(() =>
      import("../Pages/HomePages/Notications/AllNotifications")
    ),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/myAccounts",
    component: lazy(() => import("../Pages/HomePages/MyAccount")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/joinTeamRequest",
    component: lazy(() => import("../Pages/HomePages/MyAccount/JoinTeam")),
    exact: true,
    requiredRoles: individualRole
  },

  {
    path: "/chat",
    component: lazy(() => import("../Pages/HomePages/Chat")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/Occupation",
    component: lazy(() => import("../Shared/Occupation")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/profile",
    component: lazy(() =>
      import("../Pages/HomePages/MyAccount/UserProfile/index")
    ),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/reviews",
    component: lazy(() => import("../Pages/HomePages/MyAccount/MyReviews/reviews")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/reviewPlatform",
    component: lazy(() => import("../Pages/HomePages/MyAccount/MyReviews")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/connectedAccount",
    component: lazy(() => import("../Pages/HomePages/MyAccount/ConnectedAccount")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/tipConfirmation",
    component: lazy(() => import("../Pages/HomePages/GiveTip/TipConformation")),
    exact: true,
    requiredRoles: teamMemberRole.concat(userRole)
  },
  {
    path: "/CalculateTip",
    component: lazy(() => import("../Pages/HomePages/GiveTip/CalculateTip")),
    exact: true,
    requiredRoles: teamMemberRole.concat(userRole)
  },
  {
    path: "/choose-card",
    component: lazy(() =>
      import("../Pages/HomePages/GiveTip/components/AddCreditCard")
    ),
    exact: true,
    requiredRoles: teamMemberRole.concat(userRole)
  },
  {
    path: "/add-card",
    component: lazy(() =>
      import("../Pages/HomePages/GiveTip/components/AddCreditCardDetails/index")
    ),
    exact: true,
    requiredRoles: teamMemberRole.concat(userRole)
  },
  {
    path: "/home",
    component: lazy(() => import("../Pages/HomePages/Home")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/my-teams",
    component: lazy(() => import("../Pages/HomePages/TeamList")),
    exact: true,
    requiredRoles: teamMember.concat(individualRole)
  },
  {
    path: "/qrhome",
    component: lazy(() => import("../../src/Shared/ShearApp/index")),
    exact: true,
    requiredRoles: allRoles.concat(userRole)
  },
  {
    path: "/app-links",
    component: lazy(() => import("../../src/Shared/AppLink/index")),
    exact: true,
    requiredRoles: allRoles.concat(userRole)
  },
  {
    path: "/business-subscription",
    component: lazy(() =>
      import("../Pages/BusinessAccount/BusinessSubscription")
    ),
    exact: true,
    requiredRoles: businessUserRole
  },
  {
    path: "/business-detail",
    component: lazy(() =>
      import("../Pages/BusinessAccount/BusinessDetail")
    ),
    exact: true,
    requiredRoles: businessUserRole
  },
  {
    path: "/stripe",
    component: lazy(() => import("../Pages/HomePages/Stripe/Stripe")),
    exact: true,
    requiredRoles:tipRecieverRole.concat(teamOwnerRole),

  },
  {
    path: "/scanqr",
    component: lazy(() => import("../Pages/HomePages/MyAccount/QrCode/index")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/payeeDetails",
    component: lazy(() => import("../Shared/PayeeDetails/PayeeDetails.jsx")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/teamMembers",
    component: lazy(() => import("../Pages/HomePages/MyAccount/TeamMember")),
    exact: true,
    requiredRoles: teamOwnerRole.concat(teamAdminRole)
  },
  {
    path: "/feedback",
    component: lazy(() => import("../Shared/FeedbackModal/FeedbackModal.jsx")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/chatModal",
    component: lazy(() =>
      import("../Shared/TransactionModal/TransactionModal")
    ),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/*",
    component: lazy(() => import("../Shared/Error")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/ContactUs",
    component: lazy(() => import("../Pages/HomePages/MyAccount/ContactUs")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/ChangePassword",
    component: lazy(() => import("../Pages/HomePages/MyAccount/ChangePassword")),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/TeamMemberProfile",
    component: lazy(() => import("../Pages/TeamAccount/index")),
    exact: true,
    requiredRoles: teamOwnerRole
  },
  {
    path: "/TermsConditions",
    component: lazy(() =>
      import("../Pages/HomePages/MyAccount/Terms&Conditions/index")
    ),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/PrivacyPolicy",
    component: lazy(() =>
      import("../Pages/HomePages/MyAccount/Terms&Conditions/index")
    ),
    ispublic: true,
    exact: true,
    requiredRoles: userRole
  },
  {
    path: "/acknowledgement",
    component: lazy(() =>
      import("../Pages/IndividualAccount/ImportantInformation")
    ),
    exact: true,
    requiredRoles: allRoles
  },
  {
    path: "/tip-receiver-details/:payeeCode",
    component: lazy(() => import("../Shared/PayeeDetails/PayeeDetails.jsx")),
    exact: true,
    requiredRoles: allRoles.concat(userRole)
  },
  {
    path: "/*",
    component: lazy(() => import("../Shared/Error")),
    exact: true,
    requiredRoles: allRoles
  }
];

export default routes;
