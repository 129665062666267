import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  getTeamMemberRequestLists,
  makeTeamAdmin,
  removeTeamAdmin,
  getTeamByTeamCode,
  getAllTeams,
  getTeamMemberTransactionsById,
  teamMemberApprovedStatus,
  sendTeamInvite,
  getTeamProfile,
  changeRequestStatus
} from "./endpoint";
import { baseUrl } from "../../utilis/baseUrl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { store } from "../store";
import { getTeamList, getPendingList } from "./slices";
export const TeamMembersApi = createApi({
  reducer: "TeamMembersApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["TeamMembers", "GetMembers", "GetList"],
  endpoints: (builder) => ({
    getAllTeams: builder.query({
      query: (params) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: `${getAllTeams}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          toast.success("get all teams successfully", {
            autoClose: 5000
          });
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["TeamMembers"]
    }),
    getTeamsTransactionsById: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;

        return {
          url: `${getTeamMemberTransactionsById}?teamId=${params}&page=1`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          toast.success("get teams transactions", {
            autoClose: 5000
          });
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["TeamMembers"]
    }),
    getTeamMembersByTeamCode: builder.query({
      query: (params) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: `${getTeamByTeamCode}?teamCode=${params}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;
          // toast.success("get team members")
          dispatch(getTeamList(data?.data?.teamMembers));
          
        } catch (err) {
          // `onError` side-effect
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000
          });
        }
        // setTimeout(()=>{
        //   window.location.reload();
        // },2000)
      },
      providesTags: ["GetMembers"]
    }),
    getTeamRequestLists: builder.query({
      query: (params) => {
        const token = store?.getState()?.Auth?.user?.accessToken;

        return {
          url: `${getTeamMemberRequestLists}?teamCode=${params}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          // toast.success("get team requests")
      console.log("response==>",data?.data)
          dispatch(getPendingList(data?.data?.teamMembers));
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["GetList"]
    }),
    sendTeamInvitation: builder.mutation({
      query: (params) => {
        const token = store?.getState()?.Auth?.user?.accessToken;

        return {
          url: `${sendTeamInvite}`,
          method: "POST",
          body: params,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;

          toast.success("send team invitation", {
            autoClose: 5000
          });
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      invalidatesTags: ["TeamMembers"]
    }),
    makeTeamAdmin: builder.mutation({
      query: (response) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: makeTeamAdmin,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // toast.success(`${response?.username} is now an Admin of this group`);
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000
          });
        }
      },
      providesTags: ["Post"]
    }),
    removeTeamAdmin: builder.mutation({
      query: (response) => {
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: removeTeamAdmin,
          method: "POST",
          body: response,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // toast.success(`${response?.username} is now an Admin of this group`);
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Network error");
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000
          });
        }
      },
      providesTags: ["Post"]
    }),
    getTeamProfileData: builder.query({
      query: (params) => {
        const token = store?.getState()?.Auth?.user?.accessToken;

        return {
          url: `${getTeamProfile}?page=1&toTeamId=${params?.toTeamId}&teamCode=${params?.teamCode}&userId=${params?.id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching posts...'))

        try {
          const { data } = await queryFulfilled;

          // `onSuccess` side-effect
          // toast.success("send team profile");
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["TeamMembers"]
    }),
    changeRequestStatus: builder.mutation({
      query: (response) => {
        console.log("resp===>",response)
        const token = store?.getState()?.Auth?.user?.accessToken;
        return {
          url: `${changeRequestStatus}`,
          method: "PUT",
          body: { ...response },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // toast.success("Status Changed successfully", {
          //   autoClose: 5000
          // });
        } catch (err) {
          if (err?.error?.error) {
            toast?.error("Admin Limit Reached", {
              autoClose: 5000
            });
          }
          toast.error(err?.error?.data?.data?.message, {
            autoClose: 5000
          });
        }
      },
      providesTags: ["Post"]
    })
  })
});

export const {
  useChangeRequestStatusMutation,
  useGetAllTeamsQuery,
  useGetTeamsTransactionsByIdQuery,
  useSendTeamInvitationMutation,
  useGetTeamMembersByTeamCodeQuery,
  useGetTeamProfileDataQuery,
  useMakeTeamAdminMutation,
  useRemoveTeamAdminMutation,
  useGetTeamRequestListsQuery
} = TeamMembersApi;
