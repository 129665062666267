import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getThreadsUrl, getChatUrl, createMessageUrl } from "./endpoint";
import { baseUrl } from "../../utilis/baseUrl";
import { store } from "../store";
import { threads, messages } from "./slices";

export const ThreadsApi = createApi({
  reducerPath: "ThreadsApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Thread", "Message"],

  endpoints: (builder) => ({
    createThread: builder.mutation({
      query: (thread) => {
        return {
          url: getThreadsUrl,
          method: "POST",
          body: thread,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + ""
          }
        };
      },
      invalidatesTags: ["Thread"]
    }),
    getThreads: builder.query({
      query: (params) => {
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getThreadsUrl}?page=1`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(threads(data?.data?.threads));

          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["Thread"]
    }),
    getChat: builder.query({

      query: (params) => {
       
        if(params){
        const token = store.getState().Auth.user?.accessToken;
        return {
          url: `${getChatUrl}?chatRoomId=${params}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`
          }
        };}

      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(messages(data?.data?.chat));

          // dispatch(transactions());
          // `onSuccess` side-effect
          // dispatch(messageCreated('Posts received!'))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching posts!'))
        }
      },
      providesTags: ["Message"]
    }),
    createMessage: builder.mutation({
      query: (message) => {
        const token = store.getState().Auth.user?.accessToken;

        return {
          url: createMessageUrl,
          method: "POST",
          body: message,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token
          }
        };
      },
      // async onQueryStarted(id, { dispatch, queryFulfilled }) {
      //   // `onStart` side-effect
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(messages(data?.data?.chat));

      //     // dispatch(transactions());
      //     // `onSuccess` side-effect
      //     // dispatch(messageCreated('Posts received!'))
      //   } catch (err) {
      //     // `onError` side-effect
      //     // dispatch(messageCreated('Error fetching posts!'))
      //   }
      // },

      invalidatesTags: ["Message", "Thread"]
    })
  })
});

export const {
  useCreateThreadMutation,
  useGetThreadsQuery,
  useGetChatQuery,
  useCreateMessageMutation
} = ThreadsApi;
