import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Input } from "reactstrap";

const MainInputField = ({
  type,
  name,
  id,
  min,
  invite,
  step,
  state,
  placeholder,
  className,
  disabled,
  onHandleChange,
  helpertext,
  accept,
  label,
  value,
  onChange,
  error,
  style,
  shouldFocus,
  labelText,
  labelColor,
  htmlFor,
  errorStyle,
  required=true
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [shouldFocus]);
  return (
    <>
      {state != "search" ? (
        <>
          {invite == "Send Invitation" ? (
            <>
              <div
                className="text-start fw-bold mt-3 textGreen"
                // style={{
                //   color: "#499662",
                // }}
              >
                <label htmlFor={htmlFor} className="">
                  {label === undefined ? labelText : label}
                  <sup>*</sup>
                </label>
              </div>
            </>
          ) : (
            <div
              className="text-start fw-bold mt-3"
              style={{
                color: labelColor ? labelColor : "#499662",
              }}
            >
              <label htmlFor={htmlFor}>
                {label === undefined ? labelText : label}
                <sup> {required ?"*":""}</sup>
              </label>
            </div>
          )}
        </>
      ) : null}
      <input
        aria-autocomplete="none"
        autoComplete="off"
        type={type}
        name={name}
        onChange={onHandleChange}
        id={id}
        placeholder={placeholder}
        accept={accept}
        value={value}
        className={`inputField basic-drop-shadow  ${className}`}
        // onFocus={handleAutocompleteOff}
        required={true}
        min={min}
        step={step}
        innerRef={inputRef}
        style={style}
        disabled={disabled}
      />

      {error ? (
        <div className="errorMessages" style={errorStyle}>
          {helpertext}
        </div>
      ) : null}
    </>
  );
};
export default MainInputField;
