import React from "react";
import "./error.scss";
import { Link } from "react-router-dom";
const Error = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-1 fw-bold" style={{ color: "#1c6748d6" }}>
            404
          </h1>
          <p className="fs-3">
            {" "}
            <span style={{ color: "#1c6748d6" }}>Opps!</span> Page not found.
          </p>
          <p className="lead" style={{ color: "#1c6748d6" }}>
            The page you’re looking for doesn’t exist.
          </p>
          <Link to="/welcome" className="my-3 fw-bold btn border border-success" style={{ color: "#1c6748d6" }}>
            Go Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Error;
