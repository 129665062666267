import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import "./Drawer.scss"; // Create a separate CSS file for drawer styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  BellIcon,
  CheatIcon,
  Dashboard,
  HomeIcon,
  UserIdIcon,
  CheatIconActive,
  BellIconActive,
  DashboardActive,
  UserIdIconActive,
} from "../Nabar/SideBar/SvgIcons/icons";
import SideBarUserInfo from "./SideBar/SideBarUserInfo";
// ... Other imports ...



function MyNavbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  let links = [
    // ... Your links ...
  ];
  const [isShow, setIsHide] = useState("userinfon");
  const hideAndShow = () => {
    setIsHide(!isShow);
  };

 const [activeTab, setActiveTab] = useState(null);
 let location = useLocation();
useEffect(() => {
  if (location.pathname === "/welcome") {
    setActiveTab("/Home");
  } else {
    setActiveTab(location.pathname);
  }
}, [location.pathname]);
  const Items = [
    {
      name: ("Home"),
      route: "/",
      icon1: HomeIcon,
      iconActive: DashboardActive,
    },
    {
      name: ("Account"),
      route: "/Home",
      icon1: Dashboard,
      iconActive: DashboardActive,
    },
    {
      name: ("Chat"),
      route: "/chat",
      icon1: CheatIcon,
      iconActive: CheatIconActive,
    },
    {
      name: ("Notifications"),
      route: "/notifications",
      icon1: BellIcon,
      iconActive: BellIconActive,
    },
    {
      name: ("Profile"),
      route: "/myAccounts",
      icon1: UserIdIcon,
      iconActive: UserIdIconActive,
    },
  ];

  return (
    <div className="container-fluid d-md-none d-block ">
      <Container>
        <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
          <button className="toggle-button" onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <SideBarUserInfo
            className={`mt-4 mb-2 ${isShow ? "userinfo" : "d-none"}`}
          />
          <ul className="drawer-links ">
            {Items.map((e, i) => (
              <li key={i} className="bg-white">
                <Link to={e.route} onClick={() => setActiveTab(e.route)}>
                  <div
                    className={`${
                      activeTab === e.route
                        ? " textGreen activeNav d-flex align-items-center justify-content-left"
                        : " textGreen fontColorNav d-flex align-items-center justify-content-left"
                    }`}
                    onClick={()=> toggleDrawer()}
                  >
                    {activeTab === e.route ? (
                      <i className="pe-3">{e.iconActive}</i>
                    ) : (
                      <i className="pe-3">{e.icon1}</i>
                    )}
                    <p className="sd-link m-0">{e.name}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Navbar
          bg="transparent"
          expand="lg"
          className="navbarContainer d-flex justify-content-between"
        >
        </Navbar>
      </Container>
          <div className="navCenter d-flex w-100 " style={{marginTop:"-15px"}}>
            <div className="me-2">
              <button
                className="toggle-button"
                onClick={toggleDrawer}
                style={{
                  marginTop:"5px",
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>
            <div
              className="textGreen  fw-bold"
              style={{
                fontSize: "30px",
                marginLeft: "30px",
              }}
            >
              EasyTipping
            </div>
          </div>
    </div>
  );
}

export default MyNavbar;
