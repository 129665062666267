// ProtectedRoute.js

import {Navigate} from 'react-router-dom';
import React from "react";
const PublicRoutes = ({ Component, requiredRoles }) => {
  // Replace this with your actual authentication logic to get the user's role
  const userRole = "user";
  // Check if the user's role is allowed to access the route
  const isAllowed = requiredRoles.includes(userRole);
  return (<>
   {isAllowed?(<Component/>):(<Navigate to="/welcome"/>)} 
    </>
  );
};

export default PublicRoutes;
