import React from "react";
import './backButton.scss'
import {  useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';

const backHome = require("../../Assets/Images/BackHome.png");

const BackButton = () => {
    let navigate =  useNavigate()
  return (
    <div>
      <Button
      onClick={() => navigate(-1)}
      className="backButton"
    >
      <img src={backHome} alt="Back" />
    </Button>
    </div>
  );
};

export default BackButton;

