import React from 'react';
import { Button } from 'reactstrap';
import './mainButton.scss';

const MainButton = ({ spanClassName ,onHandleClick, type, text, Id, className,disabled ,style}) => {
  return (
    <>
      <Button
        onClick={onHandleClick}
        className={`${className} mainButton ${
          disabled && 'bg-secondary opacity-50'
        }`}
        type={type}
        style={style}
        // color="success"
        id={Id}
        disabled={disabled}
      >
        {text}
        <span className={spanClassName}></span>
      </Button>
    </>
  );
};

export default MainButton;
