import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { toast } from "react-toastify";
import { baseUrl } from "./baseUrl";
import axios from "axios";
// import { store } from "../Store/store";
const firebaseConfig = {
  apiKey: "AIzaSyAhGtT6OzMRyh-74wgJnJ_r2_U6kKa1Gvo",
  authDomain: "obon-aaa70.firebaseapp.com",
  projectId: "obon-aaa70",
  storageBucket: "obon-aaa70.appspot.com",
  messagingSenderId: "831792528880",
  appId: "1:831792528880:web:357cd567a5a7f7f524f135",
  measurementId: "G-YEC93M9SJ0",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestPermission = async (token, id) => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: `BKuqXYR2YHz_zELkuff_erYFRH1ROh2QY8hUh-CE2nLLB2fdrykIjEjrzw2XaDBdjIkU9tQW_GqpOY3rStJ817Y`,
      })
        .then(async (currentToken) => {
          if (token) {
            if (
              currentToken &&
              (localStorage.getItem("fcmToken") === undefined ||
                localStorage.getItem("fcmToken") === null)
            ) {
              console.log("fcmToken", localStorage.getItem("fcmToken"), token);
              let body = {
                userId: id,
                FCM: currentToken,
              };
              // toast.success("Permission of Notification Granted");
              let user = await axios.put(`${baseUrl}/user/updateToken`, body, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: `Bearer ${token}`,
                },
              });
              console.log("users--->", user);
              // dispatch(login({ user, accessToken: object?.accessToken }));
            }
            localStorage.setItem("fcmToken", currentToken);
          } else {
            console.error(
              "Failed to generate the app registration token.Kindly allow Notification Access from your Window"
            );
          }
        })
        .catch((err) => {
          console.error(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      localStorage.removeItem("fcmToken");
      console.error("User Permission Denied.");
    }
  });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });